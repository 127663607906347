import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import { getCalender } from "services/profileapis";
import { useMediaQuery, useTheme as T } from "@mui/material";

function ScrollText() {
  const [maintenenceData, setMaintenenceData] = useState([])
  const t = T()
  const isSmall = useMediaQuery(t.breakpoints.down("md"))
  const fetchData = async (enabled) => {
    try {

      const response = await Promise.all(
        enabled.map((exchange) => getCalender(exchange==="MCX" || exchange==="NSE" ?"NSE":exchange))
      );
      const getResponse  =response.flat()
      const findMaintenance = getResponse.filter(item => item.type === 'Maintenance');
      // to sort without duplicate data 
      const uniqueMaintenance = Array.from(new Set(findMaintenance.map(item => `${item.market}-${item.type}-${item.reason}-${item.date}`)))
        .map(key => {
          return findMaintenance.find(item => `${item.market}-${item.type}-${item.reason}-${item.date}` === key);
        });

      setMaintenenceData(uniqueMaintenance);
      // console.log("isMaintenance:---", uniqueMaintenance);
      // console.log("responses:------------", getResponse);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const enabled = JSON.parse(sessionStorage.getItem("enabledmarkets"));
    if(enabled){
      fetchData(enabled)
    }
  },[]);

  const maintenanceText = maintenenceData.map(item => `The ${item.market==="MCX"?"NSE":item.market} market is currently undergoing a ${item.type} due to ${item.reason} on ${item.date}.`).join(' ');

  return (
    <>
   {maintenenceData.length>0  ?  (<div
    style={{
      overflow: "hidden", // Hide the overflowed text
      whiteSpace: "nowrap", // Keep text in a single line
      backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
      padding: "0",
    }}
  >
    <MDTypography
      sx={{
        display: "inline-block",
        fontSize: "16px",
        fontWeight: "400",
        color: "#FCFBFF",
        animation: isSmall?"scroll-horizontal 10s linear infinite":"scroll-horizontal 15s linear infinite", // Keep the duration or adjust as needed
        animationTimingFunction: "cubic-bezier(0.9, 0.9, 0.9, 0.9)", // Adjust this bezier curve to control the speed
        "@keyframes scroll-horizontal": {
          "0%": {
            transform: "translateX(100%)", // Start off the right edge of the screen
          },
          "100%": {
            transform: "translateX(-100%)", // End off the left edge of the screen
          },
        },
      }}
    >
     {maintenanceText}
    </MDTypography>
  </div>):null}
  </>
  );
}

export default ScrollText;
