export const  calenderOptions = [
    {id: 'all', label: "All"},
    {id: 'today', label: "Today"},
    {id: 'week', label: "Last Week"},
    {id: 'month', label: "Last Month"},
    {id: '3months', label: "Last 3 Months"},
    {id: 'custom', label: "Custom Period"},
]



 export const weeklyOptions = [
    // {id: 'all', label: "All"},
    {id: 'thisWeek', label: "This Week"},
    {id: 'lastWeek', label: "Last Week"},
    {id: 'lastMonth', label: "Last Month"},
    {id: '3Months', label: "Last 3 Month"},
    {id: 'custom', label: "Custom Period"},
    {id:'customWeek', label:'Custom Week'}
]




