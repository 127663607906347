import { useEffect, useRef, useState, useCallback} from "react";
// import UserActivity from "../utils/userActivity"; // Import the UserActivity function

const useSocket = (url, tradingSymbol, handleReciviedData) => {
  const socketRef = useRef();

  // function for closing socket
  const closeSocket = () => {
    if (socketRef.current) {
      socketRef.current.onopen = null;
      socketRef.current.onmessage = null;
      socketRef.current.onerror = null;
      socketRef.current.onclose = null;
      socketRef.current.close();
      socketRef.current = null;
      // console.log("WebSocket connection closed");
    }
  };

  //function for opening socket
  const connectSocket = () => {
    closeSocket(); // Ensure previous socket is closed before opening a new one
    if (tradingSymbol) {
      const newSocket = new WebSocket(url);
      // storing newSocket as current socket
      socketRef.current = newSocket;

      newSocket.onopen = () => {
        // console.log(
        //   `WebSocket connection is established for ${tradingSymbol}.`
        // );
        newSocket.send(tradingSymbol);
      };

      newSocket.onmessage = (event) => {
        try {
          const receivedData = JSON.parse(event?.data);
          // console.log("Received data in socket :", receivedData);
          handleReciviedData(receivedData && receivedData);
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };

      newSocket.onerror = (event) => {
        console.error("WebSocket error observed:", event);
      };

      newSocket.onclose = (event) => {
        console.log("WebSocket is closed now.", event.reason);
        socketRef.current = null;
      };
    }
  };

  // Use UserActivity to manage socket based on user activity
  // UserActivity(closeSocket);

  useEffect(() => {
    connectSocket();
    return () => {
      closeSocket();
    };
  }, [tradingSymbol, url]);

  return { connectSocket, closeSocket };
};

export default useSocket;


export const usePortfolioWebSocket = (url) => {
  const [socketData, setSocketData] = useState([]);
  const socketRef = useRef(null);

  const handleDataReceived = useCallback((event) => {
    // console.log("Received data from WebSocket in port:", event.data);
    try {
      const data = JSON.parse(event?.data);
      // console.log("Parsed data in portfolio:", data);
      setSocketData(data);
    } catch (error) {
      // console.error("Error parsing WebSocket data:", error);
    }
  }, []);

  const handelSocketOpen = useCallback((selectedButton, id) => {
    if (socketRef.current) {
      // console.log("Closing existing WebSocket before opening a new one.");
      socketRef.current.close();
    }

    socketRef.current = new WebSocket(url);

    socketRef.current.onopen = () => {
      // console.log("WebSocket connection opened in portfolio.");
      const message = `${selectedButton}:${id}`;
      // console.log("Sending message to WebSocket:", message);
      socketRef.current.send(message);
    };

    socketRef.current.onmessage = handleDataReceived;

    socketRef.current.onclose = () => {
      // console.log("WebSocket connection closed in port.");
    };

    socketRef.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
  }, [url, handleDataReceived]);

  const handleSocketClose = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.close();
      // console.log("Socket closed.");
    }
  }, []);

  useEffect(() => {
    return () => {
      handleSocketClose();
    };
  }, [handleSocketClose]);

  return { socketData, handelSocketOpen, handleSocketClose };
}
