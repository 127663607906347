export const formatAndMultiplyValueAssetItem = (value, exchange, switchOn) => {
  const getCurrencySymbol = (isConverted) => {
    if (isConverted) {
      return "₹";
    }
    if (exchange === "NASDAQ" || exchange === "CRYPTO") {
      return "$";
    } else if (exchange === "NSE" || exchange === "MCX") {
      return "₹";
    } else {
      return "";
    }
  };
 
  const formatNumber = (number) => {
    // Convert the number to a string
    let str = number.toString();
 
    // Check if the number is an integer or effectively an integer
    if (Number.isInteger(parseFloat(str)) || parseFloat(str) === Math.floor(parseFloat(str))) {
      return `${parseFloat(str).toFixed(2)}`;
    }
 
    // Remove trailing zeros after the first non-zero decimal place
    return parseFloat(number).toFixed(6).replace(/(\.\d*?[1-9])0+$/, '$1');
  };
 
  const formatNumberWithCommas = (number) => {
    const [whole, fraction] = number.toString().split('.');
    const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return fraction ? `${formattedWhole}.${fraction}` : formattedWhole;
  };
 
  const formatValue = (val, currencySymbol) => {
    if (isNaN(val)) {
      return "0.00";
    }
 
    // Check if the value is negative
    const isNegative = val < 0;
    const absValue = Math.abs(val);
 
    if (currencySymbol === "₹") {
      // Format as Indian number system
      const [whole, fraction] = absValue.toFixed(2).split('.');
      const lastThree = whole.slice(-3);
      const otherNumbers = whole.slice(0, -3);
      const formattedWhole = otherNumbers ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + ',' + lastThree : lastThree;
      return (isNegative ? '-' : '') + (fraction ? formattedWhole + '.' + fraction : formattedWhole);
    } else {
      // Format as standard thousand separator
      const formattedNumber = absValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return (isNegative ? '-' : '') + (exchange === "CRYPTO" ? formatNumberWithCommas(formatNumber(absValue)) : formattedNumber);
    }
  };
 
  const shouldMultiply = switchOn && !(exchange === "NSE" || exchange === "MCX");
  const formattedValue = shouldMultiply ? value * 83.5 : value;
  const currencySymbol = getCurrencySymbol(shouldMultiply);
 
  return `${currencySymbol}${formatValue(formattedValue, currencySymbol)}`;
};
