import React, { useState, useEffect, useContext } from "react";
import { Grid, Modal, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MDTypography from "components/MDTypography";
import { accountDeletion } from "services/profileapis";
import showToast from "utils/toastUtils";

const DeleteModal = ({ openModal, handleCloseModal }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    handleCloseModal();
  };

  const handleAccountDelete = async () => {
    accountDeletion()
      .then((response) => {
        console.log(response);
       showToast("Account Deletion SuccessFull", "success");
        sessionStorage.removeItem("token");
        navigate("/signup");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Modal animationType="slide" transparent={true} open={openModal} onClose={handleCloseModal}>
        <MDBox
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              borderRadius: "10px",
              paddingX: "30px",
              paddingY: "6px",
            }}
          >
            <DialogTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Account Delete
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <Grid>
                <MDTypography>Are you sure you want to delete your Account?</MDTypography>
              </Grid>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <MDBox mt={2} mb={1}>
                  <MDButton variant="gradient" color="error" onClick={handleClose}>
                    Cancel
                  </MDButton>
                </MDBox>
                <MDBox mt={2} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => handleAccountDelete()}
                  >
                    Yes
                  </MDButton>
                </MDBox>
              </DialogActions>
            </DialogContent>
          </Card>
        </MDBox>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default DeleteModal;
