// import { useNavigate } from 'react-router-dom';

// const AuthGuard = ({ component: Component, ...rest }) => {
//   const navigate = useNavigate();
//   const token = sessionStorage.getItem('token');

//   if (token) {
//     // If token is present, allow access to the component
//     return <Component {...rest} />;
//   } else {
//     // If token is not present, redirect to the login page
//     navigate('/login');
//     // Return null to prevent rendering of the component
//     return null;
//   }
// };

// export default AuthGuard;


import React from 'react';
import { useNavigate } from 'react-router-dom';
// Authentication function (replace this with your actual authentication logic)
const checkAuthentication = () => {
  // Assume true for authenticated user. Replace with your actual logic.
  var token = sessionStorage.getItem('token');
  if(token)
  return true
  return false;
};
const AuthGuard = ({ component: Component, ...rest }) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!checkAuthentication()) {
      // Redirect the user to the login page if not authenticated
      navigate('/login');
    }
  });
  return checkAuthentication() ? <Component {...rest} /> : null;
};
export default AuthGuard;