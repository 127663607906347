import { jwtDecode } from "jwt-decode";

const go = process.env.REACT_APP_GO_URL;
const fast = process.env.REACT_APP_FAST_API_URL;

export const sellAndBuySymbolApi = async (data) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const response = await fetch(`${go}/api/user/makeOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    const responseData = await response.json(); // Parse JSON response in all cases
    if (response.ok) {
      return { success: true, data: responseData }; // Return a success flag and data if the response was successful
    } else {
      // If response is not ok, construct an error object based on the API's response
      return {
        success: false,
        error: responseData.message || "An error occurred",
        statusCode: response.status,
      };
    }
  } catch (error) {
    // Handle network or parsing errors
    console.error("Network or parsing error:", error);
    return {
      success: false,
      error: "Network error: Could not connect to the server",
      statusCode: 0,
    };
  }
};

export const getportifolio = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const response = await fetch(`${go}/api/user/getPortfolio`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
    });

    if (response.ok) {
      const result = await response.json();
      // console.log("result:", result);
      // Process and return the data as needed
      return result;
    } else {
      // console.error("Error fetching data:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

// export const getZportifolio = async () => {
//   try {
//     const token = sessionStorage.getItem("token");
//     if (!token) {
//       throw new Error("Authentication token not found in session storage");
//     }
//     const response = await fetch(`${go}/api/user/getZtokens`, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       redirect: "follow",
//     });

//     if (response.ok) {
//       const result = await response.json();
//       // console.log("result:", result);
//       // Process and return the data as needed
//       return result;
//     } else {
//       // console.error("Error fetching data:", response.status);
//       return null;
//     }
//   } catch (error) {
//     console.error("Error:", error);
//     return null;
//   }
// };

export const getNtokens = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const response = await fetch(`${go}/api/user/getNtokens`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
    });

    if (response.ok) {
      const result = await response.json();
      // console.log("result:", result);
      // Process and return the data as needed
      return result;
    } else {
      // console.error("Error fetching data:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const watchlist = async (marketData) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const raw = JSON.stringify(marketData);
    const response = await fetch(`${go}/api/user/watchlist`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: raw,
      redirect: "follow",
    });

    if (response.ok) {
      const result = await response.json();
      // console.log("result:", result);
      // Process and return the data as needed
      return result;
    } else {
      // console.error("Error fetching data:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const getInstruments = async (pageValues) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const raw = JSON.stringify(pageValues);
    const response = await fetch(`${go}/api/user/getInstruments`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: raw,
      redirect: "follow",
    });

    if (response.ok) {
      const result = await response.json();
      // console.log("result:", result);
      return result;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const Addwatchlist = async (data) => {
  const myHeaders = new Headers();
  const token = sessionStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found in session storage");
  }
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify(data);
  // console.log("AddWatchlist====-", data);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  try {
    const response = await fetch(`${go}/api/user/addToWatchlist`, requestOptions);

    if (response.ok) {
      return await response.json();
    } else {
      var err = await response.json();
      console.error("Addwatchlist API error response:", err?.message);
      throw new Error(err?.message);
    }
  } catch (error) {
    console.error("addToWatchlist:", error);
    return null;
  }
};

export const removeFromWatchlist = async (data) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = sessionStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found in session storage");
  }
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify(data);
  // console.log("AddWatchlist====-", data);
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  try {
    const response = await fetch(`${go}/api/user/removeFromWatchlist`, requestOptions);

    if (response.ok) {
      return await response.json();
    } else {
      var err = await response.json();
      console.error("Addwatchlist API error response:", err?.message);
      throw new Error(err?.message);
    }
  } catch (error) {
    console.error("removeFromWatchlist:", error);
    return null;
  }
};

export const removeSelectedFromWatchlist = async (data) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = sessionStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found in session storage");
  }
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify(data);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  try {
    const response = await fetch(`${go}/api/user/removeMultipleFromWatchlist`, requestOptions);

    if (response.ok) {
      return await response.json();
    } else {
      var err = await response.json();
      console.error("remove selected from watchlist API error response:", err?.message);
      throw new Error(err?.message);
    }
  } catch (error) {
    console.error("remove selected From Watchlist:", error);
    return null;
  }
};

export const Allgraphs = async (instrumentId, instrumentType) => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found in session storage");
  }
  const requestOptions = {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  };
  // console.log("allgraph-=-=-=-=-=", instrumentId, instrumentType, token )
  //alert("Hi3")
  return await fetch(
    `${go}/api/user/getSymbol?instrumentId=${instrumentId}&instrumentType=${instrumentType}`,
    requestOptions
  );
};

// export const Leverage = async (userId) => {
//   const requestOptions = {
//     method: 'GET',
//     headers: { Authorization: `Bearer ${token}` },
//   };

//   return await fetch(`${fast}/getAllUserConfig/${userId}`, requestOptions);
// };

export const Leverage = async (userId) => {
  try {
    const token = sessionStorage.getItem("token"); // Assuming the token is stored under 'authToken'
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }

    const response = await fetch(`${fast}/getAllUserConfig/${userId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      console.error("Failed to fetch user/agent leverage configuration.");
      throw new Error("Failed to fetch data"); // Throw an error to handle it in the catch block
    }
    const data = await response.json();
    // console.log(data);
    return data; // Parse and return JSON response if successful
  } catch (error) {
    console.error("Error fetching user/agent leverage configuration:", error);
    throw error; // Re-throw the error for further handling upstream if necessary
  }
};

export const getPortfolio = async (data) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = sessionStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found in session storage");
  }
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify(data);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${go}/api/user/getPortfolio`, requestOptions);

    if (response.ok) {
      return await response.json();
    } else {
      const err = await response.json();
      console.error("Purchase API error response:", err?.message);
      throw new Error(err?.message);
    }
  } catch (error) {
    console.error("There was a problem with the getPortfolio API request:", error);
    throw error;
  }
};

export const brokerages = async (userId) => {
  try {
    const token = sessionStorage.getItem("authToken");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const response = await fetch(`${fast}/user-config/${userId}`, {
      method: "GET",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    });
    const result = await response.json();

    const brokerageValue = result.config.NSE.brokerage;

    // Return the extracted brokerage value
    return brokerageValue;
  } catch (error) {
    console.error("Error fetching data from API:", error.message);
    // Return an error value or handle the error as needed
    return null;
  }
};

export const getEnabledTokens = async (userId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = sessionStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found in session storage");
  }
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${fast}/getAllEnabledInstruments/${userId}`, requestOptions);

    if (response.ok) {
      return await response.json();
    } else {
      var err = await response.json();
      console.error("Purchase API error response:", err?.message);
    }
  } catch (error) {
    console.error("getAllEnabledInstruments:", error);
    throw error;
  }
};


export const getPortifolioQuantity = async (userId, symbol, exchange) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = sessionStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found in session storage");
  }
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  try {
    const response = await fetch(
      `${fast}/getPortfolioBySymbol/${userId}/${symbol}/${exchange}`,
      requestOptions
    );

    if (response.ok) {
      return await response.json();
    } else {
      var err = await response.json();
      console.error("Purchase API error response:", err?.message);
    }
  } catch (error) {
    console.error("getPortfolioBySymbol:", error);
    throw error;
  }
};

export const onForget = async (email) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
    }),
  };

  try {
    const response = await fetch(`${fast}/forgot-password`, requestOptions);

    if (response.ok) {
      const result = await response.text();
      return { success: true, message: result };
    } else {
      // console.log(" Email not found. Please check your email address.", response.statusText);
      return { success: false, error: " Email not found. Please check your email address." };
    }
  } catch (error) {
    throw new Error(`API request failed with error: ${error.message}`);
  }
};

export const userConfig = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const id = jwtDecode(token);
    const userid = id.UserID;
    const url = `${fast}/user-config/${userid}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if(response.ok){
      const data = await response.json();
      return data;
    }else{
      const errorData   = await response.json();
      console.log(errorData)
      throw new Error("Failed to fetch user configuration");
    }
    
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`error getting user configuration: ${error.message}`);
  }
};


export const getUserOrders=async()=>{
  try{
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const response = await fetch(`${go}/api/user/openOrders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        exchange: ""
      }),
    });
    if (!response.ok) {
      throw new Error("Failed to fetch user orders");
    }
    const data = await response.json();
    return data;
  }catch(error){
    throw new Error(`error getting user orders: ${error.message}`);
  }
}

export const getMargin = async (exchange, instrumentId) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error('Authentication token not found');
    }
    const id = jwtDecode(token);
    const userid = id.UserID;
      const response = await fetch(`${fast}/getmargin/${userid}/${exchange}/${instrumentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error('Failed to fetch margin data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching margin data:', error);
    throw error;
  }
};