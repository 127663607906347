import { useEffect, useState } from "react";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import { Grid, Modal, Card } from '@mui/material';
import Switch from "@mui/material/Switch";
import FormHelperText from "@mui/material/FormHelperText";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
// Authentication layout components
import {
  onForget
} from "../../../services/portifolioapis";
// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { userLogin } from "services/userApis";
import { ToastContainer } from "react-toastify";
import showToast from "utils/toastUtils";
import 'react-toastify/dist/ReactToastify.css';
import { Typography } from "@mui/material";
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

function forgetPassword({ openModal, handleCloseModal }) {
  const navigate = useNavigate();
  const [mail, setMail] = useState("");
  const [errors, setErrors] = useState({});
  


  //Mail Validation
  const validateMail = (text) => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, mail: "Email is required" }));
      return false;
      // check the mail pattern
    } else {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      // if mail pattern is incorrect
      if (!emailPattern.test(text)) {
        setErrors((prev) => ({ ...prev, mail: "Invalid email format" }));
        return false;
        // if mail pattern is correct
      } else {
        setErrors((prev) => ({ ...prev, mail: null }));
        return true;
      }
    }
  };


  const onForgets = () => {
    if ( !mail ) {
      showToast('Please enter email.', "error");
      return;
  }
  onForget(mail)
      .then((response) => {
        if (response.success) {
          showToast('Password sent successfully:!', "success");
          
          setTimeout(() => {
            handleCloseModal();
          }, 1000);
          setMail('');
        } else {
          showToast(response.error || 'Email not found. Please check your email address.', "error");
        }
      })
      .catch((error) => {
        showToast(error.message || 'Error sending password',"error");
      });
  };


  
  const handleCancel = () =>{
    handleCloseModal();
  }


    

  return (
   <Grid >
    <Modal  open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="search-modal-title"
        aria-describedby="search-modal-description">
    
    <Card 
        style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            height: "35%",
            width: { xs: "90%", sm: "60%", md: "40%", lg: "40%", xl:"30%"},
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "5px",
          }}>
      
        <Grid sx={{ display: 'flex', flexDirection: 'row',paddingLeft: 15 }}>
          
        <MDTypography sx={{ fontWeight: 500, fontSize:'20px', textAlign: "center" }}>
          Forget Password
        </MDTypography>
       
        <Grid sx={{ paddingLeft: 12 }}>
        <IconButton onClick={handleCancel} aria-label="cancel">
           <CancelIcon />
         </IconButton>
         </Grid>
         </Grid>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2} py={"5px"}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={mail}
                onBlur={() => validateMail(mail)}
                onChange={(e) => {
                  setMail(e.target.value);
                  validateMail(e.target.value);
                }}
              />
              {errors.mail && (
                <FormHelperText sx={{ color: "#red", fontWeight: 520 }}>
                  {errors.mail}
                </FormHelperText>
              )}
            </MDBox>
            <MDBox mt={4} mb={1}>
            <MDButton
                variant="gradient"
                color="info"
                sx={{
                  bgcolor: "#C1C2EB",
                  fontWeight: "medium",
                  "&:hover": { bgcolor: "#ADAED3" },
                }}
                fullWidth
                onClick={() => onForgets()}
              >
                Forget 
              </MDButton>
            </MDBox>
          </MDBox>
         </MDBox>
        </Card>
       </Modal>
      <ToastContainer />
    </Grid>
  );
}
export default forgetPassword;