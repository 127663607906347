import { useState, useEffect, useRef } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { jwtDecode } from "jwt-decode";
import colors from "assets/theme/base/colors";
import { useMaterialUIController } from "context";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React base styles
import {
  Divider,
  Grid,
  TextField,
  Card,
  Autocomplete,
  IconButton,
  Collapse,
  useMediaQuery,
  useTheme as T,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
// Images
import backgroundImage from "assets/images/bg-profile.jpeg";
import {
  fetchUserProfile,
  getBalances,
} from "../../../../services/profileapis";
import ChangeNameModel from "../../../profile/components/models/ChangeNameModel";
import ChangePasswordmodel from "../../../profile/components/models/ChangePasswordmodel";
import DeleteModal from "../models/DeleteModal";
import { getEnabledTokens } from "../../../../services/portifolioapis";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";
function Header() {
  const t = T();
  const isXSmall = useMediaQuery(t.breakpoints.down("sm"));
  const isSmall = useMediaQuery(t.breakpoints.down("lg"));
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState("IST");
  const [id, setId] = useState();
  const [openModalPass, setOpenModalpass] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [balanceData, setBalanceData] = useState([]);
  const [socketData, setSocketData] = useState([]);
  const [open, setIsOpen] = useState(true);
  const { black, white } = colors;
  const [controller] = useMaterialUIController();
  const [enabled, setEnabled] = useState([]);
  const [loading, setLoading] = useState(false);
  const [socketBalanceData, setSocketBalanceData] = useState([]);
  const { darkMode } = controller;
  const navigate = useNavigate();

  const options = [
    { label: "IST", value: "IST" },
    { label: "ET", value: "ET" },
  ];
  const options2 = [
    { label: "INR", value: "INR" },
    { label: "USD", value: "USD" },
  ];

  const defaultOption = options.find((option) => option.value === "IST");
  // const defaultOption2 = options2.find(
  //   (option) => option.value === sessionStorage.getItem("currency")
  // );
  const defaultCurrency = sessionStorage.getItem("currency") || "USD";
  // Initialize TimeZone state with defaultOption
  const [TimeZone, setTimeZone] = useState(defaultOption);
  const [currency, setCurrency] = useState(defaultCurrency);

  const [apiData1, setApiData1] = useState({
    FirstName: "",
    LastName: "",
    FirstName1: "",
  });
  const [apiData, setApiData] = useState({
    ID: "",
    AgentID: "",
    FirstName: "",
    LastName: "",
    CreatedAt: "",
    UpdatedAt: "",
    Balance: "",
    LastLogin: "",
    PasswordUpdatedAt: "",
    FirstName: "",
    LastName: "",
    DeletedAt: "",
  });

  const token = sessionStorage.getItem("token");
  //  get enabled markets from sessionstorage

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalpress = () => {
    setOpenModalpass(true);
  };

  useEffect(() => {
    fetchData();
  }, [token, fetchUserProfile]);

  useEffect(() => {
    const userid = apiData.ID;
    fetchBalances(userid);
  }, [enabled]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) return false;
    const decodedToken = jwtDecode(token);
    setId(decodedToken.UserID);
    fetchTokens(decodedToken.UserID);
  }, []);

  const fetchBalances = async () => {
    setLoading(true);
    try {
      const result = await getBalances();
      // console.log(result);
      const data = result.wallets;
      // console.log(data)
      // console.log(enabled)

      //Filter wallets based on enabled exchanges
      const filteredWallets = enabled.map((exchange) =>
        data.find((wallet) => wallet.exchange === exchange.exchange)
      );

      // console.log("filteredWallets:---->", filteredWallets);
      setBalanceData(filteredWallets);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTokens = async (userid) => {
    try {
      // Assuming getPortfolio function is defined somewhere
      const responseData = await getEnabledTokens(userid);
      console.log("response Data:", responseData);
      setEnabled(responseData && responseData);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchData = async () => {
    try {
      const result = await fetchUserProfile();
      if (result) {
        const createdAtDate = new Date(result.CreatedAt);
        const formattedCreatedAt = `${createdAtDate.getDate()}/${
          createdAtDate.getMonth() + 1
        }/${createdAtDate.getFullYear()}`;

        const updatedAtDate = new Date(result.UpdatedAt);
        const formattedUpdatedAt = `${updatedAtDate.getDate()}/${
          updatedAtDate.getMonth() + 1
        }/${updatedAtDate.getFullYear()}`;

        const lastLoginDate = new Date(result.LastLogin);
        const formattedLastLogin = `${lastLoginDate.getDate()}/${
          lastLoginDate.getMonth() + 1
        }/${lastLoginDate.getFullYear()}`;

        const passwordUpdatedAtDate = new Date(result.PasswordUpdatedAt);
        const formattedPasswordUpdatedAt = `${passwordUpdatedAtDate.getDate()}/${
          passwordUpdatedAtDate.getMonth() + 1
        }/${passwordUpdatedAtDate.getFullYear()}`;
        // console.log(result, "result");
        setApiData(result);
      }
    } catch (error) {
      //  console.error('Error:', error);
    }
  };
  // console.log(apiData);

  const handleLogOut = () => {
    sessionStorage.removeItem("token");
    navigate("/login");
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Check for null or undefined values
    const date = new Date(dateString); // Convert string to date object
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}, ${hours}:${minutes} ${ampm}`;
  };

  useEffect(() => {
    if (balanceData && socketData.length >= 0) {
      const updatedBalances = balanceData?.map((item) => {
        const matchingData =
          socketData &&
          socketData.find(
            (data) => data?.type.toLowerCase() === item?.exchange?.toLowerCase()
          );
        // console.log("matchingData:---", matchingData);

        if (matchingData) {
          return {
            ...item,
            pNLPrice: matchingData?.PNL,
            currentPrice: matchingData?.current_price,
          };
        }
        return {
          ...item,
          pNLPrice: 0,
          currentPrice: 0,
        };
      });
      // console.log("updatedBalances:---", updatedBalances);
      setSocketBalanceData(updatedBalances);
    }
  }, [balanceData, socketData]);

  const wsRef = useRef(null);

  useEffect(() => {
    const connectWebSocket = () => {
      if (!enabled.length) {
        console.log("No enabled exchanges to fetch data.");
        return;
      }

      if (wsRef.current) {
        wsRef.current.close();
      }
      wsRef.current = new WebSocket(
        `${process.env.REACT_APP_SOCKET_URL}/getUserBalances`
      );
      wsRef.current.onopen = () => {
        wsRef.current.send(`${id}:${currency}`); // Send user_id to WebSocket server
      };

      wsRef.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          setSocketData(data);
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
          setSocketData([]);
        }
      };

      wsRef.current.onerror = (error) => {
        console.error("WebSocket error in CardItems:", error);
      };

      wsRef.current.onclose = () => {
        // console.log('WebSocket disconnected in Balance');
      };
    };

    if (id && balanceData && currency && enabled) {
      connectWebSocket();
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [id, balanceData, enabled, currency]);

  const GridHeader = ({ text }) => (
    <MDTypography
      sx={{
        fontWeight: 550,
        paddingLeft: "17px",
        fontSize: isXSmall ? "15px" : "17px",
      }}
    >
      {text}
    </MDTypography>
  );
  const columns = [
    {
      field: "Exchange",
      headerName: <GridHeader text="Wallet" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 120 : undefined,
      flex: isSmall ? undefined : 1,
      renderCell: (params) => (
        <MDTypography
          sx={{
            paddingLeft: "20px",
            paddingTop: "5px",
            fontSize: isXSmall ? "13px" : "18px",
          }}
        >
          {params.value}
        </MDTypography>
      ),
    },
    {
      field: "Balance",
      headerName: <GridHeader text="Deposit" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 180 : undefined,
      flex: isSmall ? undefined : 1,
      renderCell: (params) => {
        return (
          <MDTypography
            sx={{
              paddingLeft: "20px",
              paddingTop: "5px",
              fontSize: isXSmall ? "13px" : "18px",
            }}
          >
            {formatAndMultiplyValueAssetItem(params.value, params.row.Exchange)}
          </MDTypography>
        );
      },
    },
    {
      field: "UsedMargin",
      headerName: <GridHeader text="Used Margin" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 180 : undefined,
      flex: isSmall ? undefined : 1,
      renderCell: (params) => {
        const usedBalanceValue = Math.abs(params?.value);
        // const usedBalance= usedBalanceValue.toString().includes('e')
        // ? usedBalanceValue.toExponential().split('e-').map(item => Number(item))
        // : usedBalanceValue;
        return (
          <MDTypography
            sx={{
              paddingLeft: "20px",
              paddingTop: "5px",
              fontSize: isXSmall ? "13px" : "18px",
            }}
          >
            {formatAndMultiplyValueAssetItem(
              usedBalanceValue,
              params.row.Exchange
            )}
          </MDTypography>
        );
      },
    },
    {
      field: "AvailableMargin",
      headerName: <GridHeader text="Available Margin" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 240 : undefined,
      flex: isSmall ? undefined : 1,
      renderCell: (params) => {
        const Available = parseFloat(params.value);
        return (
          <MDTypography
            sx={{
              paddingLeft: "20px",
              paddingTop: "5px",
              fontSize: isXSmall ? "13px" : "18px",
            }}
          >
            {formatAndMultiplyValueAssetItem(Available, params.row.Exchange)}
          </MDTypography>
        );
      },
    },
    {
      field: "PNLValue",
      headerName: <GridHeader text="P&L" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 180 : undefined,
      flex: isSmall ? undefined : 1,
      renderCell: (params) => (
        <MDTypography
          sx={{
            paddingLeft: "20px",
            paddingTop: "5px",
            fontSize: isXSmall ? "13px" : "18px",
          }}
        >
          {formatAndMultiplyValueAssetItem(params.value, params.row.Exchange)}
        </MDTypography>
      ),
    },
  ];

  // map the api data for data grid rows (do null check)
  const rows = socketBalanceData.map((wallet, index) => ({
    id: index,
    Exchange: wallet?.exchange,
    Balance: wallet?.balance,
    Net: wallet?.net_balance,
    Lev: wallet?.leverage,
    charges: wallet?.charges,
    PNLValue: parseFloat(wallet?.pNLPrice) || 0,
    CurrentPrice: wallet?.currentPrice || 0,
    UsedMargin: wallet?.usedMargin,
    BookedPNL: wallet?.bookedPNL || 0,
    AvailableMargin: wallet?.availableMargin || 0,
  }));

  return (
    <Grid lg={12} md={12} sm={12} xs={12} xl={12}>
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="18.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Grid>
          <Card
            sx={{
              mt: -25,
              mx: "auto",
              py: 1,
              px: 1,
              width: isXSmall ? "98%" : "90%",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MDTypography sx={{ fontSize: isXSmall ? "14px" : "18px" }}>
                ID
              </MDTypography>
              <MDTypography sx={{ fontSize: isXSmall ? "14px" : "18px" }}>
                {" "}
                {apiData.UserID}
              </MDTypography>
            </Grid>
            <Divider />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                AgentID
              </MDTypography>
              <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                {" "}
                {apiData.OverseeUser}
              </MDTypography>
            </Grid>
            <Divider />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                Name
              </MDTypography>
              <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                {apiData1.FirstName} {apiData1.LastName}
              </MDTypography>
            </Grid>
            <Divider />
            <Grid>
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                  Balance
                </MDTypography>
                <Grid onClick={() => setIsOpen(!open)}>
                  <IconButton>
                    {open === true ? (
                      <KeyboardArrowUp
                        sx={{
                          color: darkMode ? white.main : black.main,
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    ) : (
                      <KeyboardArrowDown
                        sx={{
                          color: darkMode ? white.main : black.main,
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    )}
                  </IconButton>
                </Grid>
              </MDBox>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <MDBox sx={{ overflow: "auto" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    hideFooter={true}
                    hideFooterPagination={true}
                    rowHeight={45}
                    density="standard"
                    loading={loading}
                    disableRowSelector
                    disableRowSelectionOnClick
                    disableColumnSelector
                    disableColumnFilter
                    disableSelectionOnClick
                    autoHeight
                    disableColumnSorting
                    disableColumnMenu
                    disableColumnResize // disable column resizing
                    sx={{
                      cursor: "pointer",
                      "& .MuiDataGrid-cell:focus": {
                        outline: "none", // Removes the outline on focus
                      },
                      "& .MuiDataGrid-cell:focus-within": {
                        outline: "none", // Covers cases where the cell might have inner focusable elements
                      },
                      "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                        outline: "none", // Removes the outline on focus for column headers
                      },
                    }}
                  />
                </MDBox>
              </Collapse>
            </Grid>

            <Divider />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid sx={{ marginTop: "11px" }}>
                <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                  Time Zone
                </MDTypography>
              </Grid>
              <Autocomplete
                value={TimeZone}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  setTimeZone(newInputValue);
                }}
                options={options}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInputBase-root": {
                        height:  45,
                        width: isXSmall ? 100 : 150,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Divider />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid sx={{ marginTop: "11px" }}>
                <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                  Currency
                </MDTypography>
              </Grid>
              <Autocomplete
                value={currency}
                onInputChange={(event, newInputValue) => {
                  setCurrency(newInputValue);
                  sessionStorage.setItem("currency", newInputValue);
                }}
                options={options2}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Currency"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45,
                        width: isXSmall ? 100 : 150,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Divider />

            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                Last Login
              </MDTypography>
              <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                {formatDate(apiData.LastLogin)}
              </MDTypography>
            </Grid>
            <Divider />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                Password Updated At
              </MDTypography>
              <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                {formatDate(apiData.PasswordUpdatedAt)}
              </MDTypography>
            </Grid>
            <Divider />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                Change Password
              </MDTypography>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleOpenModalpress}
                sx={{ width:isXSmall?'140px': "170px", fontSize: isXSmall ? "10px" : "13px", textTransform: 'initial'  }}
              >
                Update Password
              </MDButton>
            </Grid>
            <Divider />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                Change Name
              </MDTypography>
              <MDButton
                variant="gradient"
                color="info"
                onClick={handleOpenModal}
                sx={{ width:isXSmall?'140px': "170px", fontSize: isXSmall ? "10px" : "13px", textTransform: 'initial' }}
              >
                Update Name
              </MDButton>
            </Grid>
            <Divider />
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <MDTypography sx={{ fontSize: isXSmall ? "13px" : "18px" }}>
                LogOut
              </MDTypography>
              <MDButton
                variant="gradient"
                color="error"
                onClick={handleLogOut}
                sx={{ width:isXSmall?'140px': "170px" , fontSize: '12px', textTransform: 'initial' }}
              >
                Log out
              </MDButton>
            </Grid>
          </Card>
        </Grid>
        <ChangeNameModel
          openModal={openModal}
          handleCloseModal={() => setOpenModal(false)}
          setApiData1={setApiData1}
        />
        <ChangePasswordmodel
          openModal={openModalPass}
          handleCloseModal={() => setOpenModalpass(false)}
          ID={apiData.ID}
        />
        <DeleteModal
          openModal={openDelete}
          handleCloseModal={() => setOpenDelete(false)}
        />
      </MDBox>
    </Grid>
  );
}

export default Header;
