import React from "react";
import MDTypography from "components/MDTypography";
import { DataGrid } from "@mui/x-data-grid";
import {format} from 'date-fns'
import {
  Card,
  CardContent,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  useMediaQuery,
  useTheme as T
} from "@mui/material";
import { useState, useEffect } from "react";
import { marketTimings } from "services/userApis";

function CalenderCard() {
  const marketType = ["NSE", "CRYPTO", "NASDAQ", "COMMODITY"];
  const [selectedMarket, setSelectedMarket] = useState(marketType[0]);
  const [holidayData, setHolidayData] = useState([]);
  const [enabledMarkets, setEnabledMarkets] = useState([]);
  const [options, setOptions] = useState([]);
  const [timings, setTimings] = useState([]);
  const [market, setMarket] = useState(null);
  const [loading, setLoading] = useState(true);
  const t= T()
  const isSmall = useMediaQuery(t.breakpoints.down("lg"));
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const enabled = sessionStorage.getItem("enabledmarkets");
    const exchange = sessionStorage.getItem("selectedExchange");
    if (enabled) {
      const markets = JSON.parse(enabled);
      const formattedOptions = markets.map((market) => ({
        label: market,
        value: market,
      }));
      setOptions(formattedOptions);
      if (formattedOptions.length > 0) {
        const defaultMarket = formattedOptions.find((option) => option.value === exchange);
        setMarket(defaultMarket || formattedOptions[0]); // Set the
      }
    }
  }, []);

  const fetchTimings = async () => {
    try {
      const response = await marketTimings();
      // console.log("market timings data from api:", response);
      const updatedTimingsData = response.map((timing)=>({
        id: timing.exchange,
        open: `Opening hours: ${timing.start_time}`,
        close:`Closing hours: ${timing.end_time}`,
        timeZone:timing.time_zone
      }))
      const filterTimings = 
      setTimings(updatedTimingsData)
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    fetchTimings();
  }, []);

  useEffect(() => {
    const storedEnabledMarkets = sessionStorage.getItem("enabledmarkets");
    if (storedEnabledMarkets) {
      setEnabledMarkets(JSON.parse(storedEnabledMarkets));
    }
  }, []);

  const handleChange = (event) => {
    setSelectedMarket(event.target.value);
  };

  // const handleChangeTimeZone = (event) => {
  //   setSelectedTimeZone(event.target.value);
  // };


  useEffect(() => {
    if (market != null) {
      fetchHolidayData(market);
    }
  }, [market]);

  // api call for fetching data
  const fetchHolidayData = async (market) => {
    setLoading(true)
    try {
      // console.log("Market:--------", market.label);
      const response = await fetch(
        `${process.env.REACT_APP_FAST_API_URL}/holidays/by-type/?type=${market.label}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      const filteredData = data.filter((item) => item.is_visible === true);
      setHolidayData(filteredData);
    } catch (error) {
      console.error("Error fetching holiday data:", error);
    }finally{
      setLoading(false)
    }
  };

  const GridHeader = ({ text }) => <MDTypography sx={{ fontWeight: 550, fontSize:'17px' }}>{text}</MDTypography>;

  const columns = [
    {
      field: "market",
      headerName: <GridHeader text="Market" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 150 : undefined, 
      flex: isSmall ? undefined : 0.5,
      renderCell: (params) => <MDTypography sx={{paddingTop:'5px', fontSize:'18px'}}>{params.value}</MDTypography>,
    },
    {
      field: "reason",
      headerName: <GridHeader text="Name" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 200 : undefined, 
      flex: isSmall ? undefined : 1.5,
      renderCell: (params) => <MDTypography sx={{paddingTop:'5px', fontSize:'18px'}}>{params.value}</MDTypography>,
    },
    {
      field: "date",
      headerName: <GridHeader text="Date" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 200 : undefined, 
      flex: isSmall ? undefined : 1,
      renderCell: (params) => <MDTypography sx={{paddingTop:'5px', fontSize:'18px'}}>{format(params.value,'dd-MM-yyyy')}</MDTypography>,
    },
    {
      field: "fullday",
      headerName: <GridHeader text="Fullday" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 200 : undefined, 
      flex: isSmall ? undefined : 1,
      renderCell: (params) => <MDTypography sx={{paddingTop:'5px', fontSize:'18px'}}>{params.value ? "Yes" : "No"}</MDTypography>,
    },
    {
      field: "starttime",
      headerName: <GridHeader text="Start Time" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 200 : undefined, 
      flex: isSmall ? undefined : 1,
      renderCell: (params) => <MDTypography sx={{paddingTop:'5px', fontSize:'18px'}}>{params.value}</MDTypography>,
    },
    {
      field: "endtime",
      headerName: <GridHeader text="End Time" />,
      sortable: false,
      filterable: false,
      width: isSmall ? 200 : undefined, 
      flex: isSmall ? undefined : 1,
      renderCell: (params) => <MDTypography sx={{paddingTop:'5px', fontSize:'18px'}}>{params.value}</MDTypography>,
    },
  ];

  return (
    <Grid container lg={12}>
      <Grid lg={12} marginBottom={"10px"} md={12} xs={12} xl={12} sm={12}>
        <Grid
          lg={12}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"flex-end"}
          marginBottom={"10px"}
        >
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: isSmall?"space-between":null,
            gap: "15px",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          xl={12}
        >
          {
            timings.map((timing) => (
              <Grid sm={12} xs={12} md={5} lg={3} gap={"20px"} alignItems={"center"} xl={2.6}>
                <Card key={timing.id} sx={{ height: "110px", marginBottom: "20px", width: "100%" }}>
                  <CardContent>
                    <MDTypography variant="h6" gutterBottom>
                      {timing.id}
                    </MDTypography>
                    <MDTypography sx={{ fontWeight: 530, fontSize: "13px" }} color="success">
                      {timing.open}
                    </MDTypography>
                    <MDTypography sx={{ fontWeight: 530, fontSize: "13px" }} color="error">
                      {timing.close}
                    </MDTypography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid
        // marginTop={"10px"}
        marginBottom={"10px"}
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        lg={12}
        md={12}
        xs={12}
        sm={12}
      >
        <Grid lg={6}>
          <MDTypography variant={isSmall ? "h5" : "h3"}>Holiday Calender</MDTypography>
        </Grid>
        <Grid>
          <FormControl sx={{ padding: "10px" }} fullWidth>
            <MDTypography id="dropdown-label" sx={{ paddingBottom: "5px", fontSize: "15px" }}>
              Select an option
            </MDTypography>
            <Autocomplete
              fullWidth
              value={market}
              onChange={(event, newValue) => {
                setMarket(newValue); // Update the selected value
              }}
              options={options}
              disableClearable
              getOptionLabel={(option) => option.label} // Ensure correct parameter name
              renderInput={(params) => (
                <TextField {...params} sx={{ "& .MuiInputBase-root": { height: 45, width:150 } }} />
              )}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid lg={12} md={12} xs={12} sm={12}>
        <DataGrid
          rows={holidayData}
          columns={columns}
          isSorted={true}
          hideFooter={true}
          showTotalEntries={false}
          getRowId={(data) => data.id}
          rowHeight={45}
          disableColumnMenu
          disableRowSelectionOnClick
          disableRowSelector={false}
          loading={loading}
          pageSize={10}
          hideFooterPagination={true}
          //disableRowSelectionOnClick
          autoHeight={holidayData.length === 0}
          sx={{
            "& .MuiDataGrid-cell:focus": {
              outline: "none", // Removes the outline on focus
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none", // Covers cases where the cell might have inner focusable elements
            },
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
              outline: "none", // Removes the outline on focus for column headers
            },
            // "& .MuiDataGrid-row:hover": {
            //   backgroundColor: "rgba(70, 130, 180, 0.5)", // Light blue color on row hover
            // },
          }}
        />
      </Grid>
    </Grid>
  );
}
export default CalenderCard;
