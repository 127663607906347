import { jwtDecode } from "jwt-decode";

const fast = process.env.REACT_APP_FAST_API_URL;
const go = process.env.REACT_APP_GO_URL;

export const fetchNotificationCount = async () => {
    try {
        const token = sessionStorage.getItem("token");
        if (!token) {
            throw new Error("Authentication token not found in session storage");
        }

        const id = jwtDecode(token);
        const userid = id.UserID;
        const response = await fetch(`${fast}/user/notifications-count/${userid}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) { 
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching notification count:", error);
        throw error;
    }
}

export const deleteNotification = async (notificationId) => {
    console.log(notificationId)
    try {
        const token = sessionStorage.getItem("token");
        if (!token) {
            throw new Error("Authentication token not found in session storage");
        }

        
        const id = jwtDecode(token);
        const userid = id.UserID;
        const response = await fetch(`${fast}/user/notifications/${userid}/${notificationId}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if(response.ok) {
            const data = await response.json();
            return  { success: true, message: data.message };
        }else{
            const errorData = await response.json();
            return { success: false, message: errorData.message };
        }

    } catch (error) {
        console.error("Error deleting notification:", error);
        throw error;
    }
}

export const readAllNotifications = async()=>{
    try{
        const token = sessionStorage.getItem("token");
        if (!token) {
            throw new Error("Authentication token not found in session storage");
        }
        const id = jwtDecode(token);
        const userid = id.UserID;

        await fetch(`${fast}/user/notifications/read-all/${userid}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log('read all notifications!')
    }catch(error){
        console.error("Error reading All notifications:", error);
        throw error;
    }
}
