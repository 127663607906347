/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import Notifications from "layouts/notifications";
// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setDarkMode,
} from "context";
import MDTypography from "components/MDTypography";
import { fetchUserProfile } from "services/profileapis";
import { fetchNotificationCount } from "services/Notifications";
import {
  Badge,
  Card,
  Dialog,
  DialogContent,
  Switch,
  useTheme as T,
  useMediaQuery,
} from "@mui/material";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openSettingMenu, setSettingMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [notificationCount, setNotificationcount] = useState()
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();
  const t = T();
  const isSmall = useMediaQuery(t.breakpoints.down("lg"));


  const getUserName = async () => {
    try {
      const result = await fetchUserProfile();
      setUserName(result?.FirstName);
    } catch (error) {
      console.error("cannot fetch username", error);
    }
  };

  const getNotificationCount = async () =>{
    try{
      const result = await fetchNotificationCount();
      // console.log('notification count', result);
      setNotificationcount(result);
    }catch(error){
      console.error("cannot fetch notification count", error);
    }
  }


  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  //(dispatch, !miniSidenav);
  const handleConfiguratorOpen = (event) => setSettingMenu(event.currentTarget);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleCloseMenu2 = () => setSettingMenu(false);
  // const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // useEffects

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    
    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);
  
  useEffect(() => {
    // Retrieve the dark mode from session storage when the app starts
    const savedDarkMode = sessionStorage.getItem('darkMode') === 'true';
    // and set the state accordingly to context
    setDarkMode(dispatch, savedDarkMode);
  }, [dispatch]);

  useEffect(() => {
    getUserName();
    getNotificationCount();
  },[]);

// DarkMode function
  const handleDarkMode = () => {
    // Save the dark mode state to session storage
    const newDarkModeState = !darkMode;
    sessionStorage.setItem('darkMode', newDarkModeState);
    // and set the state accordingly to context
    setDarkMode(dispatch, newDarkModeState);
  };

  //Logout function
  const handleLogout = () => {
    // clear the session storage on logout function
    sessionStorage.clear();
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  const renderSettingMenu = () => (
    <Menu
      anchorEl={openSettingMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openSettingMenu)}
      onClose={handleCloseMenu2}
      sx={{ mt: 2 }}
    >
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        lineHeight={1}
      >
        <MDTypography variant="h6">Light / Dark</MDTypography>
        <Switch checked={darkMode} onChange={handleDarkMode}/>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        lineHeight={1}
        paddingRight="20px"
        onClick={() => handleLogout()}
        sx={{ cursor: "pointer" }}
      >
        <MDTypography variant="h6" paddingLeft={4}>
          Logout
        </MDTypography>
        <IconButton sx={navbarIconButton}>
          <MDTypography>
            <Icon fontSize="15px">logout</Icon>
          </MDTypography>
        </IconButton>
      </MDBox>
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={route[route.length - 1]}
            route={route}
            light={light}
          />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox pr={1}>{/* <MDInput label="Search here" /> */}</MDBox>
            <MDBox
              color={light ? "white" : "inherit"}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <Link to="/authentication/sign-in/basic"> */}
              <MDTypography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Welcome {userName}
              </MDTypography>
              <IconButton sx={navbarIconButton} size="small">
                <Icon sx={iconsStyle} onClick={() => navigate("/profile")}>
                  account_circle
                </Icon>
              </IconButton>
              {/* </Link> */}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-haspopup="true"
                variant="contained"
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              {renderSettingMenu()}
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={() => {
                  setOpenModal(true), handleCloseMenu;
                }}
              >
                <Badge color="info" badgeContent={notificationCount} sx={{'& .MuiBadge-badge':{height:'17px'}}}>
                <Icon sx={iconsStyle}>notifications</Icon>
                </Badge>
              </IconButton>
              {/* {renderMenu()} */}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        fullScreen={isSmall}
        PaperProps={{
          sx: {
            borderRadius: isSmall ? "0px" : "20px",
            position: "absolute",
            top: isSmall ? "0px" : "50px",
            right: 0,
            width: "100%",
            margin: isSmall ? "0" : "",
            height: isSmall ? null : "85%",
          },
        }}
      >
        <Card sx={{ height: "100%" }}>
          <MDBox
            sx={{
              padding: "15px",
            }}
          >
            <MDTypography
              sx={{
                fontSize: "19px",
                fontWeight: 500,
              }}
            >
              Notifications
            </MDTypography>
          </MDBox>
          <IconButton
            aria-label="close"
            onClick={() => setOpenModal(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Icon sx={iconsStyle}>close</Icon>
          </IconButton>

          <DialogContent
            sx={{ "&.MuiDialogContent-root": { padding: "10px" } }}
          >
            <Notifications />
          </DialogContent>
        </Card>
      </Dialog>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
