
import React, { useState, useEffect, useContext } from "react";
import { Grid, Modal, Card } from '@mui/material';
import { updateNameApi, fetchUserProfile } from '../../../../services/profileapis'
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import showToast from "utils/toastUtils";
const Changename = ({ openModal, handleCloseModal, ID, apiData, setApiData1 }) => {
  
  const token =sessionStorage.getItem("token")

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');

  const handleFirstnameChange = (e) => {
    const value = e.target.value;
    // Use regular expression to allow only alphabetic characters
    if (/^[A-Za-z]*$/.test(value)) {
      setFirstname(value);
    }
  };

  const handleLastnameChange = (e) => {
    const value = e.target.value;
    // Use regular expression to allow only alphabetic characters
    if (/^[A-Za-z]*$/.test(value)) {
      setLastname(value);
    }
  };
  
  const updateName = async () => {
    try {
      if (firstname.trim() === '' || lastname.trim() === '') {
        throw new Error('Please enter both first name and last name');
      }
  
      // Perform API call to update name if both first name and last name are provided
      updateNameApi(firstname, lastname)
        .then(() => {
          showToast('Name updated successfully', "success");
          fetchData();
          setFirstname('');
          setLastname('');
          setTimeout(() => {
            handleCloseModal();
          }, 1000);
        })
        .catch((error) => {
          throw new Error(error.message || 'Failed to update Name');
        });
    } catch (error) {
      showToast(error.message, "error");
    }
    } 

    const handleClose = () => {
      setFirstname('');
      setLastname('');
      handleCloseModal();
    };

    const fetchData = async () => {
      const result = await fetchUserProfile();
      if (result) {
        setApiData1(result);
      }
    };
  
   
    useEffect(() => {
      fetchData();
    }, []);
  
  return (
    <>
      <Modal animationType="slide" transparent={true} open={openModal} onClose={handleCloseModal}>
      {/* <Dialog  open={openModal}
        onClose={handleCloseModal} borderRadius='30px'>  */}

     <MDBox
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              borderRadius: "10px",
              paddingX: "30px",
              paddingY: "6px",
            }}
          >

<DialogTitle style={{ textAlign: 'center',fontWeight: 'bold', fontSize: '20px' }}>Update Name</DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
          {/* style={styles.cardContainer4} */}
          <Grid mt={1} sx={{ display:'flex', flexDirection: 'column',justifyContent:'center' }}> 
          <MDBox mb={2} sx={{ display:'flex',justifyContent:'center' }}>
           <MDInput 
               type="text"
               label="Firstname"
               variant="standard"
               sx={{ width: '100%' }}
               onChange={handleFirstnameChange}
               value={firstname}
            />
           </MDBox>
         <MDBox sx={{ display:'flex',justifyContent:'center' }} >
          <MDInput 
               type="text"
               label="Lastname"
               variant="standard"
               sx={{ width: '100%' }}
               onChange={handleLastnameChange}
               value={lastname}
           />
           </MDBox>
            </Grid>
            <DialogActions sx={{ display: 'flex', justifyContent:'space-between',flexDirection:'row',gap: 1 }}>
         
             <MDBox mt={2} mb={1} >
               <MDButton variant="gradient" color="error"  onClick={handleClose}>
                Cancel
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1} >
               <MDButton variant="gradient" color="success"  onClick={()=>updateName()}>
                Update
               </MDButton>
             </MDBox>
            </DialogActions>  
        </DialogContent>
        </Card>
        </MDBox>
      {/* </Dialog> */}
      </Modal>
      <ToastContainer />
    </>
  );
};


export default Changename;