

const go = process.env.REACT_APP_GO_URL;
const fast = process.env.REACT_APP_FAST_API_URL;

export const userLogin = async (mail, password) => {

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    identifier: mail.trim(),
    password: password.trim(),
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${go}/api/auth/login`, requestOptions);
    if (response.ok) {
      const result = await response.json();
      console.log(result);
      sessionStorage.setItem("token", result.token.token);
      return result;
    } else {
      const errorResult = await response.json();
      console.error("Login failed", errorResult.message);
      return { success: false, message: errorResult.message };
    }
  } catch (error) {
    console.error("An error occurred during login:", error);
    return { success: false, message: error.message };
  }
};

export const marketTimings = async()=>{
  try{
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }

    const response  = await fetch(`${fast}/timimngs`, {
      method:'GET',
      headers:{
        Authorization: `Bearer ${token}`
      }
    })

    if(response.ok){
      const data = await response.json();
      return data;
    }else{
      console.error("HTTP Response Error: ", response.status);
    }
  }catch(error){
    console.error("Error getting market open and close timings:", error);
    throw error;
  }
}

export const getWeeklyPortfolio = async(userId, page, start_date, end_date, week)=>{
  try{
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }

    console.log(week)
    let url = `${fast}/weekly_portfolio?year=2024&limit=${page}&offset=0&user_id=${userId}`;
    
    if (week !== null && week !== undefined) {
      url += `&week_number=${week}`;
    }
    if (start_date && start_date !== "undefined") {
      url += `&start_date=${encodeURIComponent(start_date)}`;
    }
    if (end_date && end_date !== "undefined") {
      url += `&end_date=${encodeURIComponent(end_date)}`;
    }
    const response  = await fetch(url, {
      method:'GET',
      headers:{
        Authorization: `Bearer ${token}`
      }
    })

    if(response.ok){
      const data = await response.json();
      return data;
    }else{
      console.error("HTTP Response Error: ", response.status);
    }
  }catch(error){
    console.error("Error getting market open and close timings:", error);
    throw error;
  }
}