import React, { useState, useEffect, useContext } from "react";
import { Grid, Modal, Card, Button, useMediaQuery, useTheme as T } from "@mui/material";
import { updatepassword } from "../../../../services/profileapis";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDTypography from "components/MDTypography";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import showToast from "utils/toastUtils";

const ChangePasswordmodel = ({ openModal, handleCloseModal, ID }) => {
  const token = sessionStorage.getItem("token");
  const [currentPassword, setcurrentPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [apiError, setApiError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const  t= T()
  const isSmall  = useMediaQuery(t.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleButtonPress = () => {
    setcurrentPassword("");
    setConfirmPassword("");
    setnewPassword("");
    handleCloseModal();
    setConfirmPasswordError("");
    setApiError("");
    setShowPassword(false);
    setPasswordShow(false);
    setConfirmPasswordShow(false);
  };

  const hideupdatename = () => {
    handleButtonPress();
  };

  const updatepassword1 = () => {
    setApiError("");
    setConfirmPasswordError("");

    const currentPwd = currentPassword.trim();
    const newPwd = newPassword.trim();
    const confirmPwd = confirmPassword.trim();

    if (!currentPwd || !newPwd || !confirmPwd) {
      showToast("Please enter all fields.", "error");
      return;
    }

    if (newPwd.includes(" ")) {
      setApiError("New password must not contain spaces");
    }

    if (newPwd !== confirmPwd) {
      setConfirmPasswordError("New Password and Confirm Password do not match.");
      return;
    }

    if (currentPwd == newPwd) {
      setApiError("Current password and new password is same.");
      return;
    }

    updatepassword(currentPwd, newPwd, confirmPwd, token)
      .then((response) => {
        if (response.success) {
          showToast("Update Password Successfully", "success");
          setTimeout(() => {
            handleCloseModal();
          }, 1000);
          sessionStorage.removeItem(token);
          setTimeout(() => {
            navigate("/login");
          }, 1000);
          setcurrentPassword("");
          setConfirmPasswordError("");
          setShowPassword(false);
          setPasswordShow(false);
          setConfirmPasswordShow(false);
          setnewPassword("");
          setConfirmPassword("");
          setApiError("");
        } else {
          setApiError(response.error || "Current Password is Incorrect");
        }
      })
      .catch((error) => {
        showToast(error.message || "An error occurred while updating password", "error");
      });
  };

  function handleTogglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  function handlenewPasswords() {
    setPasswordShow(!passwordShow);
  }
  function handleChangeconfirmPassword() {
    setConfirmPasswordShow(!confirmPasswordShow);
  }

  return (
    <>
      <Modal animationType="slide" transparent={true} open={openModal} onClose={handleCloseModal} >
        <MDBox
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              borderRadius: "10px",
              paddingX: isSmall? "30px":"60px",
              paddingY: "6px",
              width:{
                xs:"95%",
                sm:"60%",
                md:"50%",
                lg:"40%",
                xl:"30%"}
            }}
          >
            <MDTypography style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>
              Update Password
            </MDTypography>
            <Grid style={{ textAlign: "center" }}>
              {/* <Card
          style={{
            padding: "auto",
            borderRadius: "5px",
          }}
        > */}
              {/* style={styles.cardContainer4} */}
              <Grid
                mt={3}
                xs={12} sm={12} md={12} lg={12} xl={12}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <MDBox mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                  <MDInput
                    type={showPassword ? "text" : "password"}
                    label="Enter Current Password"
                    fullWidth
                    value={currentPassword}
                    //onBlur={() => validatePassword(password)}
                    onChange={(e) => {
                      setcurrentPassword(e.target.value);
                      setApiError("");
                      // validatePassword(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <Button onClick={handleTogglePasswordVisibility} sx={{ padding: "0" }}>
                          {showPassword ? (
                            <Visibility sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                          ) : (
                            <VisibilityOff sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                          )}
                        </Button>
                      ),
                    }}
                  />
                </MDBox>
                {apiError && (
                  <MDTypography sx={{ fontSize: "12px", fontWeight: "500" }} mb={1} color="error">
                    {apiError}
                  </MDTypography>
                )}
                <MDBox mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                  <MDInput
                    type={passwordShow ? "text" : "password"}
                    label="Enter New Password"
                    fullWidth
                    value={newPassword}
                    //onBlur={() => validatePassword(password)}
                    onChange={(e) => {
                      setnewPassword(e.target.value);
                      setConfirmPasswordError("");
                    }}
                    InputProps={{
                      endAdornment: (
                        <Button onClick={handlenewPasswords} sx={{ padding: "0" }}>
                          {passwordShow ? (
                            <Visibility sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                          ) : (
                            <VisibilityOff sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                          )}
                        </Button>
                      ),
                    }}
                  />
                </MDBox>
                <MDBox mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                  <MDInput
                    type={confirmPasswordShow ? "text" : "password"}
                    label="Enter Confirm Password"
                    fullWidth
                    value={confirmPassword}
                    //onBlur={() => validatePassword(password)}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      // validatePassword(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <Button onClick={handleChangeconfirmPassword} sx={{ padding: "0" }}>
                          {confirmPasswordShow ? (
                            <Visibility sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                          ) : (
                            <VisibilityOff sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                          )}
                        </Button>
                      ),
                    }}
                  />
                </MDBox>
                {confirmPasswordError && (
                  <MDTypography
                    sx={{ fontSize: "12px", fontWeight: "500" }}
                    mb={1}
                    variant="body2"
                    color="error"
                  >
                    {confirmPasswordError}
                  </MDTypography>
                )}
              </Grid>
              <Grid sx={{ display: "flex", justifyContent: "space-around", flexDirection: "row" }}>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="error" onClick={handleButtonPress}>
                    Cancel
                  </MDButton>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="success"
                    onClick={() => updatepassword1()}
                    disabled={newPassword.includes(" ")}
                  >
                    Update
                  </MDButton>
                </MDBox>
              </Grid>
              {newPassword.includes(" ") && (
                <MDTypography color="error" sx={{ fontSize: "12px" }}>
                  new password must not contain space
                </MDTypography>
              )}
            </Grid>
          </Card>
        </MDBox>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ChangePasswordmodel;
