// react-router-dom components
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import { Grid, useMediaQuery, useTheme as T, Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userSignUp } from "services/userApis";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import showToast from "utils/toastUtils";

function signupForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mail, setMail] = useState("");
  const [overseeUser, setOverseeUser] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [agree, setAgree] = useState(true);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const t = T();
  const isSmall = useMediaQuery(t.breakpoints.down("sm"));

  const handleChange = () => {
    setAgree(!agree);
  };

  // Api call Function
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password.includes(" ")) {
      showToast("password must not contain spaces", "error");
    }
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !mail.trim() ||
      !overseeUser.trim() ||
      !password.trim() ||
      !confirmPwd.trim()
    ) {
     showToast("Fields are empty!!", "warning");
    } else {
      // Define your headers and request body
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const formData = {
        firstName: firstName,
        lastName: lastName,
        email: mail.trim(),
        password: password.trim(),
        overseeUserID: overseeUser.trim(),
      };

      console.log(formData);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ ...formData, user_role: "IU" }),
        redirect: "follow",
      };

      try {
        // Make the API call
        const response = await fetch(
          `${process.env.REACT_APP_GO_URL}/api/auth/register`,
          requestOptions
        );

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          showToast("Sign-up successful!", "success");
          setTimeout(() => {
            navigate("/login");
          }, 2000);
          return data;
        } else {
          // Handle any errors from the API
          const error = await response.json();
          showToast(error.message || "Failed to sign up. Please try again.", "error");
        }
      } catch (error) {
        // Handle any network errors
        showToast(error.message || "Failed to sign up. Please try again.", "error");
      }
    }
  };

  // Validations
  // FirstName Validation
  const validateFirstName = (text) => {
    if (!text) {
      setErrors((prev) => ({ ...prev, firstName: "First name is required" }));
      return false;
    } else {
      if (text.length < 3) {
        setErrors((prev) => ({
          ...prev,
          firstName: "First Name must be at least 3 characters",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, firstName: null }));
        return true;
      }
    }
  };
  // LastName Validation
  const validateLastName = (text) => {
    if (!text) {
      setErrors((prev) => ({ ...prev, lastName: "Last name is required" }));
      return false;
    } else {
      if (text.length < 3) {
        setErrors((prev) => ({
          ...prev,
          lastName: "Last Name must be at least 3 characters",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, lastName: null }));
        return true;
      }
    }
  };
  // Mail Validation
  const validateMail = (text) => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, mail: "Email is required" }));
      return false;
      // check the mail pattern
    } else {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      // if mail pattern is incorrect
      if (!emailPattern.test(text)) {
        setErrors((prev) => ({ ...prev, mail: "Invalid email format" }));
        return false;
        // if mail pattern is correct
      } else {
        setErrors((prev) => ({ ...prev, mail: null }));
        return true;
      }
    }
  };

  const validatePassword = (text) => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, password: "Password is required" }));
      return false;
    } else {
      if (text.length < 8) {
        // Password length validation
        setErrors((prev) => ({
          ...prev,
          password: "Password must be at least 8 characters long",
        }));
        return false;
      } else {
        setErrors((prev) => ({ ...prev, password: null }));
        return true;
      }
    }
  };

  const validateConfirm = (text) => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, confirm: "Password is required" }));
      return false;
    } else if (text !== password) {
      setErrors((prev) => ({
        ...prev,
        confirm: "Confirm password must be same as Password",
      }));
      return false;
    } else {
      setErrors((prev) => ({ ...prev, confirm: null }));
      return true;
    }
  };

  function handleTogglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  return (
    <CoverLayout>
      <Card>
        <MDBox
          variant="gradient"
          sx={{
            background: "linear-gradient(195deg, #49a3f1, #1A73E8)",
          }}
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h5" fontWeight="medium" color="white">
            Registration
          </MDTypography>
        </MDBox>
        <MDBox px={2}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              xl={12}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={isSmall ? "column" : "row"}
              mt={3}
            >
              <Grid mb={2} lg={5} md={5.5} sm={12} xs={12} xl={5.8}>
                <MDInput
                  type="text"
                  label="FirstName"
                  fullWidth
                  value={firstName}
                  onBlur={() => validateFirstName(firstName)}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    validateFirstName(e.target.value);
                  }}
                />
                {errors.firstName && (
                  <FormHelperText sx={{ color: "#F91529 ", fontWeight: 520 }}>
                    {errors.firstName}
                  </FormHelperText>
                )}
              </Grid>
              <Grid mb={2} lg={5} md={5.5} sm={12} xs={12} xl={5.8}>
                <MDInput
                  type="text"
                  label="LastName"
                  fullWidth
                  value={lastName}
                  onBlur={() => validateLastName(lastName)}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    validateLastName(e.target.value);
                  }}
                />
                <FormHelperText sx={{ color: "#F91529", fontWeight: 520 }}>
                  {errors.lastName}
                </FormHelperText>
              </Grid>
            </Grid>

            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={mail}
                onBlur={() => validateMail(mail)}
                onChange={(e) => {
                  setMail(e.target.value);
                  validateMail(e.target.value);
                }}
              />
              {errors.mail && (
                <FormHelperText sx={{ color: "#F91529 ", fontWeight: 520 }}>
                  {errors.mail}
                </FormHelperText>
              )}
            </MDBox>
            <MDBox mb={2} py={"5px"}>
              <MDInput
                type={showPassword ? "text" : "password"}
                label="Password"
                fullWidth
                value={password}
                onBlur={() => validatePassword(password)}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validatePassword(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      onClick={handleTogglePasswordVisibility}
                      sx={{ padding: "0", left: "13px" }}
                    >
                      {showPassword ? (
                        <Visibility sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                      )}
                    </Button>
                  ),
                }}
              />
              <FormHelperText sx={{ color: "#F91529 ", fontWeight: 520 }}>
                {errors.password}
              </FormHelperText>
              {password.includes(" ") && (
                <FormHelperText sx={{ color: "#F91529", fontWeight: 520 }}>
                  password must not contain space
                </FormHelperText>
              )}
            </MDBox>
            <MDBox mb={2} py={"5px"}>
              <MDInput
                type={showConfirm ? "text" : "password"}
                label="Confirm Password"
                fullWidth
                value={confirmPwd}
                onBlur={() => validateConfirm(confirmPwd)}
                onChange={(e) => {
                  setConfirmPwd(e.target.value);
                  validateConfirm(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <Button
                      onClick={() => setShowConfirm(!showConfirm)}
                      sx={{ padding: "0", left: "13px" }}
                    >
                      {showConfirm ? (
                        <Visibility sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                      )}
                    </Button>
                  ),
                }}
              />
              <FormHelperText sx={{ color: "#F91529 ", fontWeight: 520 }}>
                {errors.confirm}
              </FormHelperText>
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Agent ID"
                fullWidth
                value={overseeUser}
                onChange={(e) => setOverseeUser(e.target.value)}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox checked={agree} onChange={handleChange}/>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}
            <MDBox mt={2}>
              <MDButton
                variant="gradient"
                type="submit"
                color="info"
                disabled={!agree || password !== confirmPwd || password.length < 8 }
                sx={{
                  // Set background color of the sign-in button
                  color: "black", // Set text color of the sign-in button
                  fontWeight: "medium",
                  "&:hover": { bgcolor: "#ADAED3" },
                  "&:focus": { bgcolor: "#C1C2EB", outline: "none" }, // Set focus background color
                  "&:active": { bgcolor: "#C1C2EB" },
                }}
                fullWidth
              >
                sign up
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <ToastContainer />
    </CoverLayout>
  );
}
export default signupForm;
