import React, { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Card, Divider, FormHelperText, Grid,Fab } from "@mui/material";
import Modal from "@mui/material/Modal";
import MDInput from "components/MDInput";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
import { Support, Supportlist } from "../../../services/profileapis";
import showToast from "utils/toastUtils";

function OpenTickets({ fetchTicketDatas }) {
  const [openModel, setOpenModel] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");

  const handleOpenModel = () => {
    setOpenModel(true);
  };

  const handleCloseModel = () => {
    setOpenModel(false);
    setTitle("");
    setdescription("");
  };

  const handupdate = () => {
    fetchTicketDatas();
  };

  const openTicket = async () => {
    if (!title || !description) {
   showToast("Please Provide Title And Description", "error");
      return;
    }

    if (title === " " || description === " ") {
     showToast("Contains space", error);
      return;
    }

    try {
      const token = sessionStorage.getItem("token");
      const decode = jwtDecode(token);
      const userid = decode.UserID;

      const data = {
        user_id: userid,
        subject: title,
        description: description,
        status: "Pending",
      };

      const response = await Support(data, token);

      if (response) {
        showToast("Ticket Raised Successfully", "success");
        handupdate();
        setTitle("");
        setdescription("");
        setTimeout(() => {
          handleCloseModel();
        }, 1000);
      }
    } catch (error) {
      console.error("Error in openTicket:", error);
      showToast("An error occurred while processing your request.","error");
    }
  };

  useEffect(() => {
    fetchTicketData();
  }, []);

  const fetchTicketData = async () => {
    const token = sessionStorage.getItem("token");
    const decode = jwtDecode(token);
    const userid = decode.UserID;

    try {
      const response = await Supportlist(userid);
      const formattedResponseArray = response.map((item) => {
        const createdAtDate = new Date(item.created_at);
        // get date format from response
        const formattedCreatedAt = `${createdAtDate.getDate()}/${
          createdAtDate.getMonth() + 1
        }/${createdAtDate.getFullYear()}`;

        return {
          id: item.id,
          name: item.subject,
          status: item.status,
          description: item.description,
          createdAt: formattedCreatedAt,
        };
      });

      const sortedDataArray = formattedResponseArray.sort((a, b) => b.id - a.id);

      setTicketData(sortedDataArray);
      // console.log(sortedDataArray);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  return (
    <Grid lg={12}>
      {/* <Fab variant="extended" onClick={handleOpenModel}>New Ticket</Fab> */}
      <MDButton sx={{ padding: "15px" }} color="info" variant="gradient"  onClick={handleOpenModel}>
        New Ticket
      </MDButton>
      <Modal
        open={openModel}
        onClose={handleCloseModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              borderRadius: "10px",
              paddingX: "30px",
              paddingY: "10px",
            }}
          >
            <Grid lg={12}>
              <form>
                <MDTypography sx={{fontSize:'20px', textAlign: "center"}}>
                 Raise a Support Ticket
                </MDTypography>
                <Divider/>
                <MDBox sx={{ marginY: "10px" }}>
                  <MDTypography sx={{fontWeight: 500, fontSize:'18px'}}>Title</MDTypography>
                  <MDInput
                    placeholder="Enter Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ width: "300px" }}
                  />
                </MDBox>
                <MDBox sx={{ marginY: "10px" }}>
                  <MDTypography sx={{fontWeight: 500, fontSize:'18px'}}>Description</MDTypography>
                  <MDInput
                    placeholder="Enter Description"
                    value={description}
                    onChange={(e) => setdescription(e.target.value)}
                    multiline
                    rows={5}
                    sx={{
                      width: "300px",
                      fontSize: "25px",
                      borderRadius: "6px",
                    }}
                    required
                  />
                  <FormHelperText>
                    {description === " " && (
                      <MDTypography sx={{ fontSize: "12px", fontWeight: 500, color: "red" }}>
                        Contains space
                      </MDTypography>
                    )}
                  </FormHelperText>
                </MDBox>
                <MDBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginY: "20px",
                  }}
                >
                  <MDButton color="error" onClick={handleCloseModel}>
                    cancel
                  </MDButton>
                  <MDButton color="success" onClick={() => openTicket()}>
                    submit
                  </MDButton>
                </MDBox>
              </form>
            </Grid>
          </Card>
        </MDBox>
      </Modal>
      <ToastContainer />
    </Grid>
  );
}
export default OpenTickets;
