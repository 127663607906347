import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Graphdetailsbox from "./data/graphdetaolbox";
import Highlowvalues from "./data/highlowvalues";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Graphs from "./data/graphs";
import {
  Allgraphs,
  getPortifolioQuantity,
} from "../../services/portifolioapis";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
// Data

function Tables({ route }) {
  const { state } = useLocation();
  const {
    instrumentId,
    instrumentType,
    TradingSymbol,
    quantity,
    isMCX,
    modifyOrderType,
    modifyType,
    modifyPrice,
    modifyQuantity,
    orderId,
    selectedOption,
  } = state;
  const [apiData, setApiData] = useState([]);
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [socket, setSocket] = useState({
    openValue: "",
    closeValue: "",
    pricePer: "",
    LastPrice: "",
    Hvalue: "",
    Lvalue: "",
  });
  const [portfolioQuantity, setPortfolioQuantity] = useState(null);

  // console.log('instrumentId',instrumentId);
  // console.log('instrumentType',instrumentType);
  // console.log('TradingSymbol',TradingSymbol);
  // console.log('modifyType:--', modifyType)
  // console.log('modifyOrderType:--',modifyOrderType)
  // console.log('modifyPrice:--', modifyPrice)
  // console.log('modifyQuantity:--', modifyQuantity)

  const fetchPortfolioQuantity = () => {
    try {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.UserID;
      const exchange = instrumentType; // Modify as per actual requirement

      console.log(userId, exchange, "Fetching portfolio quantity...");

      getPortifolioQuantity(userId, TradingSymbol, exchange)
        .then((data) => {
          setPortfolioQuantity(data.total_value);
          console.log("Portfolio quantity fetched:", data.total_value);
        })
        .catch((error) => {
          console.error("Error fetching portfolio quantity:", error);
        });
    } catch (error) {
      console.error("Error decoding token or fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPortfolioQuantity();
  }, []);

  useEffect(() => {
    let newSocket;
    if (instrumentType === "NASDAQ" || instrumentType === "CRYPTO") {
      setLoading(true);
      newSocket = new WebSocket(
        `${process.env.REACT_APP_SOCKET_URL}/getNASDAQ`
      );
      newSocket.onopen = () => {
        console.log("WebSocket connection established for NASDAQ.");
        newSocket.send(TradingSymbol);
      };

      newSocket.onmessage = (event) => {
        try {
          const receivedData = JSON.parse(event.data);
          if (receivedData?.last_price) {
            setSocket((prevData) => ({
              ...prevData,
              LastPrice: receivedData && receivedData?.last_price,
              askPrice:
                receivedData && receivedData.ask_price === "None"
                  ? 0
                  : receivedData?.ask_price,
              bidPrice:
                receivedData && receivedData.bid_price === "None"
                  ? 0
                  : receivedData?.bid_price,
              askSize:
                receivedData && receivedData.ask_size === "None"
                  ? 0
                  : parseFloat(receivedData?.ask_size),
              bidSize:
                receivedData && receivedData.bid_size === "None"
                  ? 0
                  : parseFloat(receivedData?.bid_size),
              upperCircuit:
                receivedData && receivedData.upper_ckt === "None"
                  ? 0
                  : receivedData?.upper_ckt,
              lowerCircuit:
                receivedData && receivedData.lower_ckt === "None"
                  ? 0
                  : receivedData?.lower_ckt,
              socketHigh: receivedData && receivedData.high === "None"
              ? 0
              : receivedData?.high,
              socketLow: receivedData && receivedData.low === "None"
              ? 0
              : receivedData?.low,
              socketOpen: receivedData && receivedData.open === "None"
              ? 0
              : receivedData?.open,
              socketClose: receivedData && receivedData.close === "None"
              ? 0
              : receivedData?.close,
            }));
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };

      newSocket.onerror = (event) => {
        console.error("WebSocket error observed:", event);
      };

      newSocket.onclose = (event) => {
        console.log("WebSocket is closed now.", event.reason);
      };

      const myHeaders = new Headers({
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      });

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const url = `${process.env.REACT_APP_FAST_API_URL}/getOHLC/${TradingSymbol}/${instrumentType}`;
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(`${TradingSymbol} data from NASDAQ:`, data);
          setSocket((prevData) => ({
            ...prevData,
            openValue:
              data && data.open === "NA" ? 0.0 : data?.open,
            closeValue:
              data && data.close === "NA" ? 0.0 : data?.close,
            Hvalue: data && data.high === "NA" ? 0.0 : data?.high,
            Lvalue: data && data.low === "NA" ? 0.0 : data?.low,
            LastPrice: data?.close,
          }));
          setLoading(false)
        })
        .catch((error) => {
          console.error("Error fetching AAPL data:", error);
        });
    } else if (
      instrumentType === "NSE" ||
      instrumentType === "NFO" ||
      instrumentType === "MCX"
    ) {
      setLoading(true);
      newSocket = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}/getNSE`);

      newSocket.onopen = () => {
        console.log("WebSocket connection established for NSE");
        newSocket.send(TradingSymbol);
      };

      newSocket.onmessage = (event) => {
        try {
          const NseData = JSON.parse(event.data);
          if (NseData?.last_price) {
            setSocket((prevData) => ({
              ...prevData,
              LastPrice: NseData && NseData?.last_price,
              askPrice:
                NseData && NseData.ask_price === "None"
                  ? 0.0
                  : NseData?.ask_price,
              bidPrice:
                NseData && NseData.bid_price === "None"
                  ? 0.0
                  : NseData?.bid_price,
              askSize:
                NseData && NseData.ask_size === "None"
                  ? 0.0
                  : parseFloat(NseData?.ask_size),
              bidSize:
                NseData && NseData.bid_size === "None"
                  ? 0.0
                  : parseFloat(NseData?.bid_size),
              openValue:
                NseData && NseData.open === "None"
                  ? 0.0
                  : NseData?.open,
              closeValue:
                NseData && NseData.close === "None"
                  ? 0.0
                  : NseData?.close,
              Hvalue:
                NseData && NseData.high === "None"
                  ? 0.0
                  : NseData?.high,
              Lvalue:
                NseData && NseData.low === "None"
                  ? 0.0
                  : NseData?.low,
              upperCircuit:
                NseData && NseData.upper_ckt === "None"
                  ? 0.0
                  : NseData?.upper_ckt,
              lowerCircuit:
                NseData && NseData.lower_ckt === "None"
                  ? 0.0
                  : NseData?.lower_ckt,
            }));
          }
          setLoading(false);
        } catch (error) {
          console.error("Error parsing WebSocket message:", error);
        }
      };

      newSocket.onerror = (event) => {
        console.error("WebSocket error observed:", event);
      };

      newSocket.onclose = (event) => {
        console.log("WebSocket is closed now.", event.reason);
      };
    }
    return () => {
      if (newSocket) {
        console.log("Closing WebSocket connection for NASDAQ.");
        newSocket.close();
      }
    };
  }, [instrumentType]);

  useEffect(() => {
    fetchData2();
  }, [token]);

  const fetchData2 = async () => {
    try {
      const response = await Allgraphs(instrumentId, instrumentType, token);

      if (response.ok) {
        const data = await response.json();
        console.log("data----====-----====>", data);

        if (instrumentType == "NASDAQ") {
          setApiData({
            Quantities: data?.portfolioQuantity,
            symbol: data?.instrumentDetails?.StockName,
            Name: data?.instrumentDetails?.StockName,
            Price: data?.instrumentDetails?.LastPrice,
            priceVal: `$${data?.instrumentDetails?.LastPrice.toFixed(2)}`,
            instrumentType: "NASDAQ",
          });
        } else
          setApiData({
            // quantity: data.ExchangeDetails.last_quantity,
            openValue: data?.ExchangeDetails?.ohlc?.open,
            closeValue: data?.ExchangeDetails?.ohlc?.close,
            Hvalue: data?.ExchangeDetails?.ohlc?.high.toFixed(2),
            Lvalue: data?.ExchangeDetails?.ohlc?.high.toFixed(2),
            quantity: data?.ExchangeDetails?.last_quantity,
            symbol: data?.instrumentDetails?.Tradingsymbol,
            Name: data?.instrumentDetails?.Name,
            price: data?.instrumentDetails?.Name,
            price: `$${data?.instrumentDetails?.LastPrice.toFixed(2)}`,
            instrumentType: data?.instrumentDetails?.Exchange,
          });
        setApiData((prevState) => ({
          ...prevState,
          LastPrice: data.instrumentDetails.LastPrice,
        }));
      } else {
        // console.error('Error fetching Allgraphs:', response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={3}>
        {loading ? (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress color="info" />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Graphs symbol={TradingSymbol} />
            <Graphdetailsbox
              Name={TradingSymbol}
              price={socket.LastPrice}
              quantity={portfolioQuantity}
              instrumentId={instrumentId && instrumentId}
              getQuantity={fetchPortfolioQuantity}
              instrumentType={instrumentType}
              askValue={socket.askPrice}
              bidValue={socket.bidPrice}
              upperCircuit={socket.upperCircuit}
              lowerCircuit={socket.lowerCircuit}
              isMCX={isMCX}
              modifyOrderType={modifyOrderType}
              modifyPrice={modifyPrice}
              modifyType={modifyType}
              modifyQuantity={modifyQuantity}
              orderId={orderId}
              selectedOption={selectedOption}
            />
            <Highlowvalues
              highValue={socket.Hvalue}
              LowValue={socket.Lvalue}
              Open={socket.openValue}
              CloseValue={socket.closeValue}
              price={socket.LastPrice}
              instrumentType={instrumentType && instrumentType}
              askValue={socket.askPrice}
              bidValue={socket.bidPrice}
              askSize={socket.askSize}
              bidSize={socket.bidSize}
              socketHigh={socket.socketHigh}
              socketLow={socket.socketLow}
              socketOpen={socket.socketOpen}
              socketClose={socket.socketClose}
            />
          </Grid>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
