import { useState } from "react";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { Button } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { userLogin } from "services/userApis";
import { ToastContainer } from "react-toastify";
import showToast from "utils/toastUtils";
import 'react-toastify/dist/ReactToastify.css';
import Forgetpassword from "./forgetpassword";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function signIn() {
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");

  //Mail Validation
  const validateMail = (text) => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, mail: "Email is required" }));
      return false;
      // check the mail pattern
    } else {
      // const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      // // if mail pattern is incorrect
      // if (!emailPattern.test(text)) {
      //   setErrors((prev) => ({ ...prev, mail: "Invalid email format" }));
      //   return false;
      //   // if mail pattern is correct
      // } else {
        setErrors((prev) => ({ ...prev, mail: null }));
        return true;
      
    }
  };

  //Validate Password
  const validatePassword = (text) => {
    // if there is no text on blur event
    if (!text) {
      setErrors((prev) => ({ ...prev, password: "Password is required" }));
      return false;
    } else {
      // if (text.length < 8) {
      //   // Password length validation
      //   setErrors((prev) => ({
      //     ...prev,
      //     password: "Password must be at least 8 characters long",
      //   }));
      //   return false;
      // } else {
        setErrors((prev) => ({ ...prev, password: null }));
        return true;
    }
  };

  function handleTogglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };


  const onLogin = async () => {
   try{
    if(mail==="" && password==="" || mail==="" || password===""){
      showToast("Fields are Empty!", "error")
    }

    if(mail.startsWith("AG-") || mail.startsWith("SA-") || mail.startsWith("SU-") || mail.startsWith("AU-")){
      setApiError('Unauthorized User')
      return
    }
    const response = await userLogin(mail, password, navigate);
    if (response.status === "success") {
      console.log("Login response:---", response.is_enabled)
      // If login is successful, navigate to the dashboard
      if(response.user_id.startsWith("IU-")){
        showToast("Login Successful!", "success");
        if(response.password_reset===true){
          navigate("/set-password");
        }else{
          setTimeout(() => {
            navigate("/dasboard");
          },2000)
        }
       }else{
         setApiError("Unauthorized User")
         navigate("/login");
       }
      localStorage.setItem("isActive", response.is_enabled)
      sessionStorage.setItem("currency", 'INR')
      
    } else {
      // If login is not successful, show an error toast message
      if(response.message==="Password"){
        setApiError("*Incorrect Password")
      }else if(response.message==="Invalid email or Password"){
        setApiError("*Invaid Email or Password")
      }else{
        setApiError('')
      }
    }
   }catch(error){
    console.log(error)
   }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onLogin();
    }
  };

  return (
    <BasicLayout>
      <Card>
        <MDTypography
          sx={{ textAlign: "center", pt: "5px" }}
          color="info"
          variant="h5"
          fontWeight="bold"
        >
          Sign In
        </MDTypography>
        <MDBox pt={2} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2} py={"5px"}>
              <MDInput
                type="email"
                label="Email/ UserId"
                fullWidth
                value={mail}
                onBlur={() => validateMail(mail)}
                 // onKeyPress={handleKeyPress}
                onChange={(e) => {
                  setMail(e.target.value);
                  validateMail(e.target.value);
                }}
              />
              {errors.mail && (
                <FormHelperText sx={{ color: "#F91529 ", fontWeight: 520 }}>
                  {errors.mail}
                </FormHelperText>
              )}
            </MDBox>
            <MDBox mb={2} py={"5px"}>
              <MDInput
                type={showPassword ? "text" : "password"}
                label="Password"
                fullWidth
                value={password}
                onBlur={() => validatePassword(password)}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validatePassword(e.target.value);
                }}
                 onKeyPress={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <Button
                      onClick={handleTogglePasswordVisibility}
                      sx={{ padding: "0", left: "13px" }}
                    >
                      {showPassword ? (
                        <Visibility sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "#9CAEB8" }} style={{ fontSize: "2rem" }} />
                      )}
                    </Button>
                  ),
                }}
              />
              <FormHelperText sx={{ color: "#F91529 ", fontWeight: 520 }}>
                {errors.password}
              </FormHelperText>
            </MDBox>
            <MDBox mt={1} mb={1}>
              <MDTypography
                component={Link}
                onClick={handleOpenModal}
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Forget Password
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                sx={{
                  bgcolor: "#C1C2EB",
                  fontWeight: "medium",
                  "&:hover": { bgcolor: "#ADAED3" },
                }}
                fullWidth
                onClick={() => onLogin()}
              >
                sign in
              </MDButton>
              <FormHelperText sx={{ color: "#F91529 ", fontWeight: 520, textAlign: "center", fontSize: "13px", marginTop: "10px"}}>
                {apiError}
          </FormHelperText>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/signup"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDTypography color="error" marginTop="10px" fontSize="14px" sx={{fontWeight:500, textAlign:"center"}}>*This App is intended for educational purposes only. Deviation from intended use will result in account closure.</MDTypography>
      <Forgetpassword openModal={openModal} handleCloseModal={() => setOpenModal(false)} />
      <ToastContainer/>
    </BasicLayout>
  );
}
export default signIn;
