import { createSlice } from "@reduxjs/toolkit";

const userAuthSlice =createSlice({
    name:"userAuth",
    initialState:{
        isActive:true,
        enabledMarkets:[]
    },
    reducers:{
        setUserStatus: (state, action) => {
            state.isActive = action.payload;
          },

          setEnabledExchanges: (state, action) => {
            state.enabledMarkets = action.payload;
          },
    }
})

export const {setUserStatus, setEnabledExchanges} = userAuthSlice.actions

export default userAuthSlice.reducer
