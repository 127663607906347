const fast = process.env.REACT_APP_FAST_API_URL;
const go = process.env.REACT_APP_GO_URL;
export const getTranscation = async (
  user_id,
  start_date,
  end_date,
  page,
  page_size,
  market
) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    // Construct the URL conditionally based on start_date, end_date, page, and page_size
    const exchange = market.label ==="ALL"? "" : market.label
    let url = `${fast}/transactions/${user_id}?exchange=${exchange}&page=${page}&page_size=${page_size}`;
    if (start_date && start_date !== "undefined") {
      url += `&start_date=${encodeURIComponent(start_date)}`;
    }
    if (end_date && end_date !== "undefined") {
      url += `&end_date=${encodeURIComponent(end_date)}`;
    }

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw error;
  }
};

export const getTrades = async (
  user_id,
  start_date,
  end_date,
  page,
  page_size,
  market
) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }

    const exchange = market.label ==="ALL"? "" : market.label
    let url = `${fast}/trades/${user_id}?exchange=${exchange}&page=${page}&page_size=${page_size}`;
    if (start_date && start_date !== "undefined") {
      url += `&start_date=${encodeURIComponent(start_date)}`;
    }
    if (end_date && end_date !== "undefined") {
      url += `&end_date=${encodeURIComponent(end_date)}`;
    }

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data: ", error);
    throw error;
  }
};

export const deleteOrder = async (data) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const raw = JSON.stringify(data);
    const response = await fetch(`${go}/api/user/cancelOrder`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: raw,
    });
    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
    }
    const removalData = await response.json();
    return removalData;
  } catch (error) {
    console.error("Error Deleting Order: ", error);
    throw error;
  }
};

export const makeOrder = async (data) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const raw = JSON.stringify(data);
    const response = await fetch(`${go}/api/user/modifyOrder`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: raw,
    });
    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
    }
    const getData = await response.json();
    return getData;
  } catch (error) {
    console.error("error performing order: ", error);
    throw error;
  }
};
