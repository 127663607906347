import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import colors from "../../../assets/theme/base/colors";
import { useMaterialUIController } from "../../../context";

function graphs({ symbol }) {
  const [widget, setWidget] = useState(null);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  // Load TradingView widget script

  //console.log("light, dark modes",darkMode)
  const loadTradingViewWidget = () => {
    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/tv.js";
    script.async = true;
    script.onload = () => {
      const newWidget = new window.TradingView.widget({
        container_id: "tradingview-widget-container",
        autosize: true,
        symbol: symbol, // Example symbol, replace with your desired symbol
        interval: "D",
        timezone: "Etc/UTC",
        theme: !darkMode ? "light" : "dark",
        style: "1",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        allow_symbol_change: false,
        hide_top_toolbar: false,
        save_image: false,
        studies: [],
        hide_legend: false,
        details: false,
        withdateranges: false,
        disabled_features: ["compare_symbol", "header_compare"],
      });

      // Set the initialized widget to state
      setWidget(newWidget);
    };
    document.body.appendChild(script);
  };

  // Load TradingView widget on component mount
  useEffect(() => {
    loadTradingViewWidget();
  }, [symbol, darkMode]);

  return (
    <Grid item xs={12} lg={9}>
      <Card sx={{ height: "460px" }}>
        <MDBox id="tradingview-widget-container" sx={{ height: "460px" }} />
      </Card>
    </Grid>
  );
}

export default graphs;
